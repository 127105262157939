import Image from 'components/Image'
import TextButton from 'components/TextButton'
import Overline from 'components/Overline'
import FourUp from 'modules/FourUp'
import Link from 'components/Link'
import useMedia from 'use-media'
import { screens } from 'lib/constants'

export default function HalfWidthFeatureFourUp({ filter, slots, articles }) {
  const isScreenLarge = useMedia({ minWidth: screens.l })
  const feature = slots?.[0]?.article ?? articles?.[0]
  const fourUp = articles?.slice(1) ?? []

  if (!feature && !fourUp.length) return null

  return (
    <div className="l:grid grid-cols-2 gap-16 l:gap-30 xl:gap-50">
      <div className="mb-50">
        {feature?.featuredImage ? (
          <Link link={feature.link} tabIndex="-1">
            <Image
              image={feature.featuredImage}
              width={345}
              height={195}
              sizes={`100vw, (min-width: ${screens.l}) 50vw`}
              className="rounded mb-20 l:mb-40"
            />
          </Link>
        ) : null}
        <div>
          {!filter && feature?.primaryTag?.title ? (
            <Overline className="mb-15 l:mb-30" underline={false}>
              <Link link={feature.primaryTag.link}>{feature.primaryTag.title}</Link>
            </Overline>
          ) : null}
          <h3 className="text-20 s:text-24 m:text-28 xl:text-32 font-medium leading-130">
            <Link link={feature.link}>{feature.title}</Link>
          </h3>
          {feature?.excerpt ? (
            <p className="text-15 l:text-16 xl:text-20 mt-10 l:mt-20">{feature.excerpt}</p>
          ) : null}
          <TextButton
            className="mt-15 l:mt-40"
            size={isScreenLarge ? 'large' : 'compact'}
            chevron
            link={feature.link}
            tabIndex="-1"
          >
            Read More
          </TextButton>
        </div>
      </div>
      <FourUp filter={filter} slots={slots?.slice(1) ?? []} articles={fourUp} />
    </div>
  )
}
